import { Button, Container, IconButton, Typography } from "@mui/material"
import { TTemplate, getTemplates } from "../utils/network/templates";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, GridValueOptionsParams } from "@mui/x-data-grid";
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID } from "../utils/web3";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useQuery } from "@tanstack/react-query";

const SkuIndex = () => {

  const { data, isLoading } = useQuery({
    queryKey: ['SKUs'],
    queryFn: async () => {
      return await getTemplates()
    },
  })

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'SKU ID',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/skus/${params.value}`}
          startIcon={<EditIcon />}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      type: 'actions',
      width: 40,
      renderCell: (params: GridValueOptionsParams<TTemplate>) => {
        return [
          <IconButton disabled={!params.row?.analytics_url} color="primary" href={params.row?.analytics_url || ''} target="_blank">
            <LeaderboardIcon/>
          </IconButton>
        ]
      },
      field: 'analytics'
    },
    {
      field: 'tags_count',
      headerName: 'Total Tags',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/tags?sku=${params.row.id}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'name',
      headerName: 'Item Name',
      minWidth: 250,
      type: 'string',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      minWidth: 300,
      type: 'string',
    },
    {
      field: 'tag_view_verifications_count',
      headerName: 'Views',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/views?sku=${params.row.id}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'viewed_tags_count',
      headerName: 'Viewed Tags',
      width: 100,
      type: 'number',
    },
    {
      field: 'owned_tags_count',
      headerName: 'Owned Tags',
      width: 100,
      type: 'number',
    },
    {
      field: 'email_consent_tags_count',
      headerName: 'Emails Collected',
      width: 100,
      type: 'number',
    },
    {
      field: 'claim_count',
      headerName: 'Owner Claims',
      width: 100,
      type: 'number',
    },
    {
      field: 'reset_count',
      headerName: 'Owner Resets',
      width: 100,
      type: 'number',
    },
    {
      field: 'verified_tags_count',
      headerName: 'QC',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        if (params.row.tags_count === params.row.verified_tags_count) {
          return <CheckCircleIcon color="success" />
        }
        return `${params.row.verified_tags_count}/${params.row.tags_count}`
      }
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 180,
      type: 'dateTime',
      valueGetter: (value: number) => {
        return new Date(value);
      }
    },
    {
      field: 'tag_verifications_count',
      headerName: 'Total Verifications',
      width: 150,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/views?sku=${params.row.id}`}
          fullWidth
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'external_tag_verifications_count',
      headerName: 'API Verifications',
      width: 100,
      type: 'number',
    },
    {
      field: 'nft_chain_id',
      headerName: 'Blockchain',
      width: 140,
      type: 'string',
      valueGetter: (value: NETWORK) => {
        console.log(value)
        if (!value) {
          return '';
        } else if (!Object.values(NETWORK).includes(value)) {
          return 'Unknown';
        }
        return NETWORK_NAME_BY_CHAIN_ID[value];
      },
    }
  ]

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">SKUs</Typography>
      <Button href="/skus/new" variant="contained" sx={{ my: 2 }}
        startIcon={<AddIcon />}
      >
        New SKU
      </Button>
      <DataGrid
        rows={data}
        autoHeight
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default SkuIndex
